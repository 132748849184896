export const SIGNALR_HUB_NAME = 'message-hub';

export enum SignalRMethod {
  NOTIFICATION = 'notification',
  PERMISSION = 'permission',
}

export enum SignalRNotificationType {
  CHAT_MESSAGE_RECEIVED = 'chat-message-received',
}
